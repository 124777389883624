const SU_STATUS_TABLE_COLUMN = [
    {
        name: "",
        select: "label",
        style: { minWidth: 400 }
    }
]

const STATUS_TABLE_INTERFACE = [
    {
        title: 'A la Saisie',
        data: [
            { label: "Réception", select: "recue" },
            { label: "Séparation", select: "categorisee" },
            { label: "A l'imputation", select: "aImputer" },
            { label: "A reporter", select: "areporter" }
        ]
    },
    {
        title: 'Imputée',
        data: [
            { label: "Imputée", select: "imputee" },
            { label: "A terminer", select: "aTerminer" },
            { label: "Avec Commentaire", select: "avecRemarque" },
            { label: "Dont import IA", select: 'ia' }
        ]
    },
    {
        title: 'Non lettrée',
        data: [{ label: "Saisie vérifiée", select: "reviseInterne" }]
    },
    {
        title: 'Revisée',
        data: [
            { label: "Revisée 1.1", select: "revisee11" },
            { label: "Revisée Compta", select: "revisee" }
        ]
    },
    {
        title: 'Non Comptable',
        data: [
            { label: "Non Comptable Validée", select: "ncValidee" },
            { label: "Non Comptable Non validée", select: "ncNonValidee" }
        ]
    },
    {
        title: 'Banque',
        data: [
            { label: "Banque Validée", select: "banqueValidee" },
            { label: "Banque Encours", select: "banqueEncours" },
            { label: "Banque Fako revue", select: "banqueFakoRevue" },
            { label: "Banque Fako à revoir", select: "banqueFakoAReviser" }
        ]
    },
    {
        title: 'Fako',
        data: [
            { label: "Fako revue", select: "fakoRevue" },
            { label: "Fako à revoir", select: "fakoAReviser" }
        ]
    },
    {
        title: 'Dossier Permanent',
        data: [{ label: "Dossier Permanent", select: "multiExercice" }]
    }
]

const CATEGORY_ID = {
    FISCAL: 21
}
const DEFAULT_OPTIONS = {
    label: 'TOUTES',
    value: "0",
    images: []
}

const SU_CONTEXT_INTERFACE = {
    imageName: "",
    setImageName: () => {},
    handleFetchImages: async () => {},
    setStatus: () => {},
    status: null,
    options : {
        categorieOptions: [DEFAULT_OPTIONS],
        sousCategorieOptions : [DEFAULT_OPTIONS],
        statusOptions: {
            all: [{ label: "Tous" }],
            part: STATUS_TABLE_INTERFACE
        }
    },
    setOptions: {
        setCategorieOptions: () => {},
        setSousCategorieOptions: () => {}
    },
    dateScans: [],
    handle: {
        dossier: async () => {},
        status: async () => {},
        periode: async () => {},
        imageName: async () => {},
        category: () => {}
    },
    textValue: "",
    setTextValue: () => {},
    setCategorieFilter: () => {},
    categorieFilter: {
        category: DEFAULT_OPTIONS,
        sous_category: DEFAULT_OPTIONS
    },
    champSelectedOcr : { 
        mono: {},
        multi: [],
        playOcr: '',
        play: 0,
        current: {
            display: 'mono',
            coordonnees: []
        },
        lastCoordonnees: {},
        ocrTagMap: {}
    },
    suOcrTag: {
        mono: '',
        multi: {
            codeArt: '', 
            qte: 1,
            ht: 0,
            codeTva: '',
            mntTva: 0,
            rabais: 0, 
            libelle: '',
            pu: 0,
            ttc: 0,
            taux: 0,
            remise: 0
        },
        coordonnees: [],
        newRow: null,
        updateRow: null
    },
    suTvaTaux: [],
    suUniteArticle: [],
    suSiretSave: {},
    siretDetails: {},
    currentImagePage: 1,
    refreshImage: false,
    componentInfo: {
        bloc_identification: {
            key: 'bloc_identification',
            isOpen: false,
            isFocused: false
        },
        bloc_occurrences: {
            key: 'bloc_occurrences',
            isOpen: false,
            isFocused: false
        },
        bloc_donnees: {
            key: 'bloc_donnees',
            isOpen: false,
            isFocused: false
        },
        bloc_recap_ecriture: {
            key: 'bloc_recap_ecriture',
            isOpen: false,
            isFocused: false
        }
    },
    imageInfo: {
        previousImage: {},
        currentImage: {},
        nextImage: {}
    },
    utilisateurInstruction: {
        saisie_allowed: {
            allowed: false,
            instruction_saisie: false,
            instruction_dossier: false
        },
        lecture_instruction_dossier: [],
        lecture_instruction_saisie: []
    },
    componentRefs: [],
    analyseData: [],
    componentFocused: null
}

const INFO_IMAGE_LIBELLE = {
    rs: '',
    num_facture: '',
    date_livraison: '',
    periode_debut: '',
    periode_fin: '',
    mode_reglement: '',
    num_paiement: '',
    chrono: '',
    nature: ''
}

const COMPTE_DATA_MODEL = {
    dossier_id: null,
    type: 3,
    type_compte: '',
    tiers_list: [],
    pcc_list: []
}

const COMPONENT_REF_KEY = {
    saisie_ecriture: 'saisie-ecriture'
}

export {
    SU_STATUS_TABLE_COLUMN,
    STATUS_TABLE_INTERFACE,
    SU_CONTEXT_INTERFACE,
    DEFAULT_OPTIONS,
    CATEGORY_ID,
    INFO_IMAGE_LIBELLE,
    COMPTE_DATA_MODEL,
    COMPONENT_REF_KEY
}