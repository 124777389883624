/* eslint-disable no-invalid-this */
class Utils {
    _doublon = 'DOUBLON'
    _doublon_min = 'Doublon'
    _anpc = 'ANPC'
    _illisible = 'ILLISIBLE'
    _instance = 'INSTANCE'
    _regex = new RegExp(`${this._doublon_min}|${this._doublon}|${this._anpc}|${this._illisible}|${this._instance}`, 'g')
    _fako(image) {
        return (this._regex.test(image.categorie?.new_libelle) || image.souscategorie?.classe === 1 || image.soussouscategorie?.classe ===  1)
    }
    isFako(image) {
        return (
            image.categorie?.non_comptable !== 1 &&
            this._fako(image)
            && image.ecriture.length === 0
        )
    }

    isBanqueFako(image) {
        return (
            image.source_image_id !== 17 && this._fako(image)
        )
    }

    isRevue(image) {
        return image.valider > 0 || image.status === 9
    }

    isBanque(categorie_id) {
        return categorie_id === 16
    }
}

export default Utils