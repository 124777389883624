import { faFile, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileText, faFileWord, faFileZipper, faFolderClosed, faFolderOpen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import '../assets/scss/common/icon-style.scss'

export const FILE_CONSTANT = {
    image: {
        extension: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'heif', 'heic'],
        icon: <FontAwesomeIcon icon={faFileImage} className='file-icon-image'/>
    },
    docx: {
        extension: ['docx'],
        icon: <FontAwesomeIcon icon={faFileWord} className='file-icon-word'/>
    },
    excel: {
        extension: ['xlsx'],
        icon: <FontAwesomeIcon icon={faFileExcel} className='file-icon-excel'/>
    },
    powerpoint: {
        extension: ['pptx'],
        icon: <FontAwesomeIcon icon={faFilePowerpoint} className='file-icon-powerpoint'/>
    },
    pdf: {
        extension: ['pdf'],
        icon: <FontAwesomeIcon icon={faFilePdf} className='file-icon-pdf'/>
    },
    zip: {
        extension: ['zip'],
        icon: <FontAwesomeIcon icon={faFileZipper} className='file-icon-archive'/>
    },
    text_file: {
        extension: ['txt'],
        icon: <FontAwesomeIcon icon={faFileText} className='folder-icon-text'/>
    },
    video: {
        extension: ['mp4', 'webm', 'avi', 'mov', 'mkv', 'wmv', 'flv', '3gp', 'mpeg', 'mpg', 'ogv', 'm4v', 'ts', 'f4v'],
        icon: <FontAwesomeIcon icon={faFileZipper} className='file-icon-video'/>
    },
    folder_open: {
        extension: ['folder_open'],
        icon: <FontAwesomeIcon icon={faFolderOpen} className='folder-icon'/>
    },
    folder_close: {
        extension: ['folder_close'],
        icon: <FontAwesomeIcon icon={faFolderClosed} className='folder-icon'/>
    },
    default_file: {
        extension: [],
        icon: <FontAwesomeIcon icon={faFile} className='file-icon-text' />
    }
}