import { createSlice } from '@reduxjs/toolkit'

export const rapprochementSlice = createSlice({
    name: 'rapprochement',
    initialState: {
        indexModeSaisie: 0,
        indexMigrationCompta: 0,
        indexHistoriqueMigration: 0
    },
    reducers: {
        handleIndexModeSaisieChange: (state, action) => {
            state.indexModeSaisie = action.payload
        },
        handleIndexMigrationComptaChange: (state) => {
            state.indexMigrationCompta = state.indexMigrationCompta + 1
        },
        handleIndexHistoriqueMigrationComptaChange: (state) => {
            state.indexHistoriqueMigration = state.indexHistoriqueMigration + 1
        }        
    }
})

export const {
    handleIndexModeSaisieChange,
    handleIndexMigrationComptaChange,
    handleIndexHistoriqueMigrationComptaChange
} = rapprochementSlice.actions

export default rapprochementSlice.reducer