// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentification',
  initialState: {
    userData: initialUser(),
    liens: [],
    forgotPassIndex: 0
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.userData
      state.liens = action.payload.userData.liens
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      state.liens = []
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleLiensChange: (state, action) => {
      state.liens = action.payload
    },
    handleUserDataChange: (state, action) => {
      state.userData = action.payload
    },
    handleForgotPassIndexChange: (state) => {
      state.forgotPassIndex = state.forgotPassIndex + 1
    }    
  }
})

export const { 
  handleLogin, 
  handleLogout, 
  handleLiensChange, 
  handleUserDataChange,
  handleForgotPassIndexChange
} = authSlice.actions

export default authSlice.reducer
