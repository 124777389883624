import _ from "underscore"
import { DEFAULT_OPTIONS } from "./su.interface"
import Utils from "./Utils"

/**
 * generate array of range number
 * @param {Number} start 
 * @param {Number} stop
 * @param {Number} step
 * @returns {Array}
 */
const arrayRange = (start, stop, step = 1) => {
    return Array.from({ length: ((stop - start) / step) + 1 }, (value, index) => (start + index) * step)
}

/**
 * filter su images by name
 * @param {Array<Object>} images 
 * @param {Array<Object>} number 
 */
const findImagesByNumber = (images, number) => {
    return images.filter(image => image.nom.includes(number))
}

/**
 * @param {Array<Object>} images 
 * @param {'categorie'|'souscategorie'} type 
 * @returns {Object}
 */
const filterCategories = (images, type = 'categorie') => {
    const categorie = {}
    const utils = new Utils()
    images.map((image) => {
      // Vérifier si l'image a une catégorie valide
      if (!image[type] || _.isNull(image[type]) || _.isNull(image[type]?.id)) return 0
  
      // Vérifier si l'image appartient à la catégorie "banque"
      if (utils.isBanque(image[type].id)) {
        let key, title
        
        if (utils.isBanqueFako(image)) {
          // Déterminer la clé et le titre pour "banque fako"
          key = utils.isRevue(image) ? '02' : '03'
          title = utils.isRevue(image) ? 'Banque Fako revue' : 'Banque Fako à revoir'
        } else {
          // Clé et titre pour les images "banque" non "fako"
          key = utils.isRevue(image) ? '04' : '05'
          title = utils.isRevue(image) ? 'Banque revue' : 'Banque à revoir'
        }
  
        // Ajouter l'image dans la catégorie appropriée
        if (categorie.hasOwnProperty(key)) {
          categorie[key]?.data.push(image)
        } else {
          categorie[key] = {
            title,
            data: [image]
          }
        }
      } else {
        // Si l'image n'est pas "banque", vérifier si elle est "fako"
        if (utils.isFako(image)) {

          const key = utils.isRevue(image) ? '0' : '01'
          const title = utils.isRevue(image) ? 'Fako revue' : 'Fako à revoir'
  
          // Ajouter l'image dans la catégorie appropriée
          if (categorie.hasOwnProperty(key)) {
            categorie[key]?.data.push(image)
          } else {
            categorie[key] = {
              title,
              data: [image]
            }
          }
        } else {
          // Ajouter les images normales qui ne sont ni "banque" ni "fako"
          if (categorie.hasOwnProperty(image[type].id)) {
            categorie[image[type].id]?.data.push(image)
          } else {
            categorie[image[type].id] = {
              title: image[type]?.new_libelle,
              data: [image]
            }
          }
        }
      }
    })
    
    return categorie
  }

/**
 * @param {Array<Object>} images 
 * @param {'categorie'|'souscategorie'} type 
 * @returns {Array<{
 * label: string,
 * value: string,
 * images: Array<Object>
 * }>}
 */
const getCategorieOptionsByImage = (images, type = 'categorie') => {
    const categories = filterCategories(images, type)
    return [
        {
            label: 'TOUTES',
            value: "0"
        },
        ...Object.keys(categories).map(key => ({
            label: `${categories[key].title} [${categories[key].data.length}]`,
            value: key,
            images: categories[key].data
        }))
    ]
}

/**
 * @param {Array<Object>} statusInterface
 * @param {Array<Object>} response 
 * @returns {Array<Object>} 
 */
const factorStatusInterface = (statusInterface, response) => {
    return statusInterface.map(s => {
        return {
            ...s,
            data: s.data.map(data => {
                const nD = { ...data }
                Object.keys(response).map(key => {
                    nD[key] = response[key][data.select]
                })
                return nD
            })
        }
    })
}

/**
 * @param {Object} statusInterface 
 * @param {Array<Object>} response
 * @returns {Array<Object>} 
 */
const getTousStatusNombre = (statusInterface, response) => {
    const nD = {
        label: "Tous",
        select: "tous"
    }
    Object.keys(response).map(key => {
        nD[key] = response[key].tous
    })
    return [nD]
}

/**
 * make compte as option param
 * @param {Array<Object} comptesList 
 * @param {string} name
 * @returns {Array<Object>} 
*/
const getCompteSelectFormat = (comptesList, name) => {
    const comptes = []
    comptesList.forEach(compte => {
        const compteType = {name, value: -1, label: '', display_label: '', type: 'pcc'}
        if (compte.compte) {
            compteType.value = compte.id
            compteType.label = `${compte.compte} - ${compte.intitule}`
            compteType.display_label = compte.compte
        } else {
            compteType.value = compte.id
            compteType.label = `${compte.compte_str} - ${compte.intitule}`
            compteType.display_label = compte.compte_str
            compteType.type = 'tiers'
        }
        if (compteType.value !== -1) {
            comptes.push(compteType)
        }
    })
    return comptes
}

/**
 * filter compte type from list
 * @param {string} type 
 * @param {Array<Object>} pccList
 * @param {Array<Object>} tiersList
 * @returns {Array<Object>} 
 */
const getComptes = (type, pccList = [], tiersList = []) => {
    let comptes = []
    if (type === 'bilan') {
        comptes = pccList.filter(pcc => parseInt(pcc.compte.substring(0, 1)) < 5 &&  pcc.compte.substring(0, 3) !== '445')
        const comptesTiers  = tiersList.filter(tiers => tiers.type === 0 || tiers.type === 3)
        comptes = [...comptes, ...comptesTiers]
    } else if (type === 'resultat') {
        comptes = pccList.filter(pcc => parseInt(pcc.compte.substring(0, 1)) > 5)
    } else if (type === 'tva') {
        comptes = pccList.filter(pcc => pcc.compte.substring(0, 3) === '445')
    }
    comptes.sort((first, last) => {
        const compteFirst = first.compte ? first.compte : first.compte_str
        const compteLast = last.compte ? last.compte : last.compte_str
        return compteFirst < compteLast
    })
    return comptes
}

/**
 * filter compte type from list
 * @param {string} type 
 * @param {Array<Object>} pccList
 * @param {Array<Object>} tiersList
 * @returns {Array<Object>} 
 */
const getComptesModel = (type, pccList = [], tiersList = []) => {
    const compteList = []
    const comptes = getComptes(type, pccList, tiersList)
    comptes.forEach(compte => {
        const newCompte = {
            intitule: compte.intitule,
            status: compte.status
        }
        if (compte.hasOwnProperty('compte_str')) {
            newCompte.id = `tiers-${compte.id}`
            newCompte.compte = compte.compte_str
            newCompte.pcc = compte.pcc
        } else {
            newCompte.id = `pcc-${compte.id}`
            newCompte.compte = compte.compte
            newCompte.collectif_tiers = compte.collectif_tiers
            newCompte.pcc = compte.pcc
        }
        compteList.push(newCompte)
    })
    return compteList
}

const renderComptesModel = (comptes = []) => {
    const compteList = []
    comptes.forEach(compte => {
        const newCompte = {
            intitule: compte.intitule,
            status: compte.status
        }
        if (compte.hasOwnProperty('compte_str')) {
            newCompte.id = `tiers-${compte.id}`
            newCompte.compte = compte.compte_str
            newCompte.pcc = compte.pcc
        } else {
            newCompte.id = `pcc-${compte.id}`
            newCompte.compte = compte.compte
            newCompte.collectif_tiers = compte.collectif_tiers
            newCompte.pcc = compte.pcc
        }
        compteList.push(newCompte)
    })
    return compteList
}

const getComptesCollectif = (type = undefined, pccList = []) => {
    const comptes = pccList.filter(pcc => pcc.collectif_tiers === type)
    return comptes
}

// ** Render Select Data
const getSelectLabel = (optionObject, label, separator = '-') => {
    let selectLabel = ''
    if (Array.isArray(label)) {
        selectLabel = ''
        label.forEach((property, index) => {
            selectLabel = `${selectLabel} ${optionObject[property] ?? ''}`
            if (index < label.length - 1) {
                selectLabel = `${selectLabel} ${separator}`
            }
        })
    } else {
        selectLabel = optionObject[label]
    }

    if (typeof selectLabel === 'string') {
        selectLabel = selectLabel.trim()
    }
    return selectLabel ?? ''
}

/**
 * render modeReglement data to Select form model
* @param {Array<Object>} modeReglements 
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getModeReglementSelect = (modeReglements = [],  property = {}, name, defaultValue) =>  {
    let modeReglementSelect = []

    modeReglements.forEach(modeReglement => {
        const modeReglementOption = {
            ...(name && {name}),
            value: property.value ? modeReglement[property.value] : modeReglement.id,
            label: getSelectLabel(modeReglement, property.label),
            display_label: getSelectLabel(modeReglement, property.display_label)
        }

        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                modeReglementOption[propertyName] = modeReglement[property.additionnalProperty[propertyName]]
            }
        }
        modeReglementSelect = [...modeReglementSelect, modeReglementOption]
    })
    if (defaultValue) {
        modeReglementSelect = [defaultValue, ...modeReglementSelect]
    }
    return modeReglementSelect
}

/**
 * render journalDossier data to Select form model
* @param {Array<Object>} journalDossiers
* @param {Array<int>} categories
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getJournalDossierSelect = (journalDossiers = [], categories = [], property = {}, name) =>  {
    let journalDossierSelect = []
    let journalDossierList = journalDossiers
    if (categories.length > 0) {
        journalDossierList = journalDossiers.filter(journalDossier => categories.includes(journalDossier.categorie_id))
    }

    journalDossierList.forEach(journalDossier => {
        const journalDossierOption = {
            ...(name && {name}),
            value: property.value ? journalDossier[property.value] : journalDossier.id,
            label: getSelectLabel(journalDossier, property.label),
            display_label: getSelectLabel(journalDossier, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                journalDossierOption[propertyName] = journalDossier[property.additionnalProperty[propertyName]]
            }
        }
        journalDossierSelect = [...journalDossierSelect, journalDossierOption]
    })
    return journalDossierSelect
}

/**
 * render taux TVA data to Select form model
* @param {Array<Object>} tauxTva 
* @param {int} actif
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getTauxTvaSelect = (tauxTva = [], actif = 1, property = {}, name) =>  {
    let tauxTvaSelect = []
    let tauxTvaList = tauxTva
    if (actif >= 0) {
        tauxTvaList = tauxTvaList.filter(taux => taux.actif === actif)
    }
    tauxTvaList.forEach(taux => {
        const tauxTvaOption = {
            ...(name && {name}),
            value: property.value ? taux[property.value] : taux.id,
            label: getSelectLabel(taux, property.label),
            display_label: getSelectLabel(taux, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                tauxTvaOption[propertyName] = taux[property.additionnalProperty[propertyName]]
            }
        }
        tauxTvaSelect = [...tauxTvaSelect, tauxTvaOption]
    })
    return tauxTvaSelect
}

/**
 * render unite article data to Select form model
* @param {Array<Object>} uniteArticle 
* @param {int} supprimer
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getUniteArticleSelect = (uniteArticle = [], supprimer = 0, property = {}, name) =>  {
    let uniteArticleSelect = []
    let uniteArticleList = uniteArticle
    if (supprimer >= 0) {
        uniteArticleList = uniteArticleList.filter(unite => unite.supprimer === supprimer)
    }

    uniteArticleList.forEach(unite => {
        const uniteOption = {
            ...(name && {name}),
            value: property.value ? unite[property.value] : unite.id,
            label: getSelectLabel(unite, property.label),
            display_label: getSelectLabel(unite, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                uniteOption[propertyName] = unite[property.additionnalProperty[propertyName]]
            }
        }
        uniteArticleSelect = [...uniteArticleSelect, uniteOption]
    })
    return uniteArticleSelect
}


/**
 * render Mot clé data to Select form model
* @param {Array<Object>} motCles 
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getMotClesSelect = (motCles = [], property = {}, name, emptyValue = true) =>  {
    let motClesSelect = []
    
    if (emptyValue) {
        motClesSelect.push({
            ...(name && {name}),
            value: -1,
            label: '',
            display_label: ''
        })
    }

    motCles.forEach(motCle => {
        const motCleOption = {
            ...(name && {name}),
            value: property.value ? motCle[property.value] : motCle.id,
            label: getSelectLabel(motCle, property.label),
            display_label: getSelectLabel(motCle, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                motCleOption[propertyName] = motCle[property.additionnalProperty[propertyName]]
            }
        }
        motClesSelect = [...motClesSelect, motCleOption]
    })
    return motClesSelect
}

/**
 * render SousCatégories data to Select form model
* @param {Array<Object>} sousCategories
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getSousCategoriesSelect = (sousCategories = [], property = {}, name, emptyValue = true) =>  {
    let sousCategoriesSelect = []

    if (emptyValue) {
        sousCategoriesSelect.push({
            ...(name && {name}),
            value: -1,
            label: '',
            display_label: ''
        })
    }

    sousCategories.forEach(sousCategorie => {
        const sousCategorieOption = {
            ...(name && {name}),
            value: property.value ? sousCategorie[property.value] : sousCategorie.id,
            label: getSelectLabel(sousCategorie, property.label),
            display_label: getSelectLabel(sousCategorie, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                sousCategorieOption[propertyName] = sousCategorie[property.additionnalProperty[propertyName]]
            }
        }
        sousCategoriesSelect = [...sousCategoriesSelect, sousCategorieOption]
    })
    return sousCategoriesSelect
}

/**
 * render SousSousCatégories data to Select form model
* @param {Array<Object>} sousSousCategories
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getSousSousCategoriesSelect = (sousSousCategories = [], property = {}, name, emptyValue = true) =>  {
    let sousSousCategoriesSelect = []

    if (emptyValue) {
        sousSousCategoriesSelect.push({
            ...(name && {name}),
            value: -1,
            label: '',
            display_label: ''
        })
    }
    
    sousSousCategories.forEach(sousSousCategorie => {
        const sousSousCategorieOption = {
            ...(name && {name}),
            value: property.value ? sousSousCategorie[property.value] : sousSousCategorie.id,
            label: getSelectLabel(sousSousCategorie, property.label),
            display_label: getSelectLabel(sousSousCategorie, property.display_label)
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                sousSousCategorieOption[propertyName] = sousSousCategorie[property.additionnalProperty[propertyName]]
            }
        }
        sousSousCategoriesSelect = [...sousSousCategoriesSelect, sousSousCategorieOption]
    })
    return sousSousCategoriesSelect
}

/**
 * render Compte data to Select form model
* @param {Array<Object>} comptes
* @param {int} status
* @param {Array<int>} collectif
* @param {Object} property
* @param {string} name
* @returns {Array<Object>} 
 */
const getComptesSelect = (comptes = [], status = 1, collectif = [], property = {}, type_compte, name, emptyValue = true) =>  {
    let comptesSelect = []
    let comptesList = comptes

    if (status >= 0) {
        comptesList = comptesList.filter(compte => compte.status === status)
    }
    
    if (collectif.length > 0) {
        comptesList = comptesList.filter(compte => {
            if (compte.hasOwnProperty('collectif_tiers')) {
                return collectif.includes(compte.collectif_tiers)
            }
            return true
        })
    }

    if (emptyValue) {
        comptesSelect.push({
            ...(name && {name}),
            value: -1,
            label: '',
            display_label: ''
        })
    }

    comptesList.forEach(compte => {
        const comptesOption = {
            ...(name && {name}),
            value : property.value ? compte[property.value] : compte.id,
            label: getSelectLabel(compte, property.label),
            display_label: getSelectLabel(compte, property.display_label),
            ...(compte.pcc && {pcc: {...compte.pcc}}),
            type_compte
        }
        if (property.additionnalProperty) {
            for (const propertyName in property.additionnalProperty) {
                comptesOption[propertyName] = compte[property.additionnalProperty[propertyName]]
            }
        }
        comptesSelect = [...comptesSelect, comptesOption]
    })
    return comptesSelect
}

const getConditionSousSousCategorie = (sousSousCategorie) => {
    let sousSousCategorieDecision = {
        tva: {
            show: true,
            disabled: false
        },
        vehicule: {
            show: false,
            disabled: false,
            option: [
                { name: 'vehicule', value: 0, label: 'Utilitaire' },
                { name: 'vehicule', value: 1, label: 'Touristique' },
                { name: 'vehicule', value: 1, label: 'Je demande au client' }
            ]
        },
        restaurant: {
            show: false,
            disabled: false
        },
        indemnite_km: {
            show: false,
            disabled: false
        }
    }
    if (sousSousCategorie) {
        const decisions = sousSousCategorie.soussouscategorie_decision
        decisions.forEach(decisionItem => {
            const decision = decisionItem.decision
            if (decision.libelle === 'P01') {
                sousSousCategorieDecision = {...sousSousCategorieDecision,
                    tva: {...sousSousCategorieDecision.tva,
                        show: true,
                        disabled: true
                    }
                }
            }
        })
    }
    return sousSousCategorieDecision
}
/**
 * 
 * @param {{
 * value: Boolean
 * }} categorie 
 * @returns {Boolean}
 */
const isTousCategorieSelected = (categorie) => categorie.value === DEFAULT_OPTIONS.value


/**
 * 
 * @param {Array} ecritures
 * @param {String} type
 * @returns {Array}
 */
const getRecapEcriture = (ecritures, type = 'ecriture') => {
    const recap = {
        total_debit: 0,
        total_credit: 0,
        list: []
    }

    if (type === 'details_ecriture') {
        const ecritureModel = {
            id: 0,
            group_id: 0,
            date: '',
            compte: null,
            journal: null,
            debit: 0,
            credit: 0,
            details_image_id : 0,
            type: -1,
            souscategorie: null,
            soussouscategorie: null,
            libelle: '',
            libelle_nature: ''
        }

        ecritures.forEach(detailsImage => {
            detailsImage.details_ecriture.forEach(ecriture => { 
                let compte = ecritureModel.compte
                let groupId = 0
                if (ecriture.tiers) {
                    groupId = `tiers-${ecriture.tiers.id}-${ecriture.type}`
                    compte = {
                        id: ecriture.tiers.id,
                        compte: ecriture.tiers.compte_str,
                        intitule: ecriture.tiers.intitule,
                        type: 'tiers'
                    }
                } else if (ecriture.pcc) {
                    groupId = `pcc-${ecriture.pcc.id}-${ecriture.type}`
                    compte = {
                        id: ecriture.pcc.id,
                        compte: ecriture.pcc.compte,
                        intitule: ecriture.pcc.intitule,
                        type: 'pcc'
                    }
                }

                const newEcriture = {...ecritureModel,
                    id: ecriture.id,
                    group_id: groupId,
                    date: detailsImage.date_ecr ?? ecritureModel.date,
                    compte,
                    journal: detailsImage.journal_dossier ?? ecritureModel.journal,
                    debit: ecriture.debit,
                    credit: ecriture.credit,
                    type: ecriture.type,
                    libelle: ecriture.libelle ?? ecritureModel.libelle,
                    libelle_nature: ecriture.libelle_nature ?? ecritureModel.libelle_nature,
                    details_image_id : detailsImage.id,
                    souscategorie: detailsImage.souscategorie,
                    soussouscategorie: detailsImage.soussouscategorie
                }
                
                recap.total_debit = recap.total_debit + newEcriture.debit
                recap.total_credit = recap.total_credit + newEcriture.credit

                const ecritureIndex = recap.list.findIndex(element => element.group_id === newEcriture.group_id)
                if (ecritureIndex >= 0) {
                    recap.list[ecritureIndex] = {...recap.list[ecritureIndex],
                        libelle: recap.list[ecritureIndex].libelle ??  newEcriture.libelle,
                        libelle_nature: recap.list[ecritureIndex].libelle_nature ??  newEcriture.libelle_nature,
                        debit: recap.list[ecritureIndex].debit + newEcriture.debit,
                        credit: recap.list[ecritureIndex].credit + newEcriture.credit
                    }
                    recap.list[ecritureIndex].ecriture_details.push(newEcriture)
                } else {
                    // console.log(recap.list)
                    const newRecap = {...newEcriture,
                        ecriture_details: []
                    }

                    newRecap.ecriture_details.push(newEcriture)
                    recap.list.push(newRecap)
                }
            })
        })
    } else {

    }
    // recap.list = recap.list.sort((ecriture_a, ecriture_b) => ecriture_a.type - ecriture_b.type)
    recap.list = recap.list.sort((ecriture_a, ecriture_b) => {
        if (ecriture_b.debit !== ecriture_a.debit) {
            return ecriture_b.debit - ecriture_a.debit
        }

        return ecriture_b.credit - ecriture_a.credit
    })
    return recap
}

const getTypeTiersByCategorie = (categorieId)  => {
    if (categorieId === 10) {
        return 0
    } else if (categorieId === 9) {
        return 1
    } else {
        return 3
    }
}

const sortImageByStatus = (images) => {
    return [...images].sort((last, next) => last.status_new - next.status_new)
}

export {
    arrayRange,
    findImagesByNumber, 
    isTousCategorieSelected,
    filterCategories,
    getCategorieOptionsByImage,
    factorStatusInterface,
    getTousStatusNombre,
    getCompteSelectFormat,
    getComptes,
    getComptesModel,
    renderComptesModel,
    getComptesCollectif,
// ** Render Select Data
    getModeReglementSelect,
    getJournalDossierSelect,
    getMotClesSelect,
    getSousCategoriesSelect,
    getSousSousCategoriesSelect,
    getTauxTvaSelect,
    getUniteArticleSelect,
    getComptesSelect,
// ** Decision
    getConditionSousSousCategorie,
// ** Ecritures
    getRecapEcriture,
// ** Compte
    getTypeTiersByCategorie,
// ** Images
    sortImageByStatus
}