// ** React Imports
import React, { createContext, useReducer } from 'react'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// ** Initial User Function
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  return item ? JSON.parse(item) : {}
}

// ** Initial State
const initialState = {
  userData: initialUser(),
  liens: [],
  userPassword: null
}

// ** Auth Reducer
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
      return {
        ...state,
        userData: action.payload.userData,
        liens: action.payload.userData.liens,
        [config.storageTokenKeyName]: action.payload.accessToken,
        [config.storageRefreshTokenKeyName]: action.payload.refreshToken
      }

    case 'LOGOUT':
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      return {
        ...state,
        userData: {},
        liens: [],
        [config.storageTokenKeyName]: null,
        [config.storageRefreshTokenKeyName]: null
      }

    case 'UPDATE_LIENS':
      return {
        ...state,
        liens: action.payload
      }

    case 'UPDATE_USER_DATA':
      return {
        ...state,
        userData: action.payload
      }

    case 'USER_PASSWORD':
      localStorage.setItem('USER_PASSWORD', JSON.stringify(action.payload))
      return {
        ...state,
        userPassword: action.payload
      }  
    default:
      return state
  }
}

// ** Create Context
const AuthContext = createContext(initialState)

// ** Auth Provider Component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  // ** Define actions
  const handleLogin = (userData) => {
    dispatch({
      type: 'LOGIN',
      payload: { ...userData }
    })
  }

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
  }

  const handleLiensChange = (liens) => {
    dispatch({ type: 'UPDATE_LIENS', payload: liens })
  }

  const handleUserDataChange = (userData) => {
    dispatch({ type: 'UPDATE_USER_DATA', payload: userData })
  }

  const handleUserPassword = (user) => {
    dispatch({ type: 'USER_PASSWORD', payload: user })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        handleLogin,
        handleLogout,
        handleLiensChange,
        handleUserDataChange,
        handleUserPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)